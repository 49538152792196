// Imports
// ------------
import styled from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------------
export const Jacket = styled.img`
    display: block;
    width: 10rem;
    height: 3.6rem;

    ${breakup.large`
        width: 11.7rem;
        height: 4.2rem;
    `}
`;
