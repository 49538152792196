// Imports
// ------------
import React from 'react';
import logo from '@images/logo.svg';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const Logo = () => (<Jacket src={logo} alt="Seek • Logo" />);

export default Logo;