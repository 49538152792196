// Imports
// ------------
import React from 'react';
import Icon from '@icons';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const CloseNav = ({ onClick, className }) => (
    <Jacket onClick={onClick} className={className}>
        <Icon type="close" />
    </Jacket>
);

export default CloseNav;