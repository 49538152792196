// Imports
// ------------
import React from 'react';
import Logo from '@parts/Logo';
import Copyright from '@parts/Copyright';
import { action } from 'mobx';
import { menuState, loadingState } from '@states';
import { observer } from 'mobx-react-lite';
import { AnimatePresence } from "framer-motion";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------------
import { Jacket, Brand, Content, Title, Excerpt, Nav, NavItem } from './styles';

// Component
// ------------
const Hero = ({ title, excerpt }) => {
    // NOTE • Breakpoints
    const bp = useBreakpoint();

    const handleTeam = action(() => {
        menuState.isOpen = true;
        menuState.team = true;
    });

    const handleHistory = action(() => {
        menuState.isOpen = true;
        menuState.history = true;
    });

    const handleCompanies = action(() => {
        menuState.isOpen = true;
        menuState.companies = true;
    });

    // NOTE • Animations
    const animation = {
        before: {
            x: '-12%',
            opacity: 0
        },
        show: {
            x: `0%`,
            opacity: 1
        },
        hide: {
            x: '-12%',
            opacity: 0
        },
    }


    return (
        <Jacket isReady={loadingState.isFinished}>
            <Brand>
                <Logo />
            </Brand>

            <AnimatePresence>
                {!menuState.isOpen && (
                    <>
                        <Content>
                            <Title
                                variants={animation}
                                initial="before"
                                animate="show"
                                exit="hide"
                                transition={{
                                    duration: 1,
                                    ease: [0.8, 0, 0.2, 1]
                                }}
                            >{title}</Title>
                            {bp.large ? (
                                <Excerpt
                                    variants={animation}
                                    initial="before"
                                    animate="show"
                                    exit="hide"
                                    transition={{
                                        duration: 1,
                                        ease: [0.8, 0, 0.2, 1]
                                    }}
                                >For media enquiries<br /><a className="cursor-trigger" href={`mailto:${excerpt}`}>{excerpt}</a></Excerpt>) : null}
                        </Content>

                        <Nav
                            variants={animation}
                            initial="before"
                            animate="show"
                            exit="hide"
                            transition={{
                                duration: 1,
                                ease: [0.8, 0, 0.2, 1]
                            }}
                        >
                            <NavItem type="button" onClick={handleTeam} className="cursor-trigger">
                                <span data-label="Team">Team</span>
                            </NavItem>

                            <NavItem type="button" onClick={handleHistory} isMiddle className="cursor-trigger">
                                <span data-label="History">History</span>
                            </NavItem>

                            <NavItem type="button" onClick={handleCompanies} className="cursor-trigger">
                                <span data-label="Companies">Companies</span>
                            </NavItem>
                        </Nav>
                    </>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {menuState.isOpen && (
                    <>{bp.large && <Copyright />}</>
                )}
            </AnimatePresence>
        </Jacket>
    );
}

export default observer(Hero);