// Imports
// ------------
import React, { useEffect, useState } from 'react';
import CloseNav from './CloseNav';
import Icon from '@icons';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';
import { menuState } from '@states';
import { AnimatePresence } from "framer-motion";

// Styles
// ------------
import { Jacket, MainPages, LegalPage, Page } from './styles';

// Component
// ------------
const NavBar = () => {
    // NOTE • States
    const [activePage, setActivePage] = useState('');

    // NOTE • Click Events
    const handleTeam = action(() => {
        menuState.team = true;
        [menuState.history, menuState.companies, menuState.legal, menuState.detailed] = false;
    });

    const handleHistory = action(() => {
        menuState.history = true;
        [menuState.team, menuState.companies, menuState.legal, menuState.detailed] = false;
    });

    const handleCompanies = action(() => {
        menuState.companies = true;
        [menuState.team, menuState.history, menuState.legal, menuState.detailed] = false;
    });

    const handleLegal = action(() => {
        menuState.legal = true;
        [menuState.team, menuState.history, menuState.companies, menuState.detailed] = false;

    });

    const handleClose = action(() => {
        [menuState.isOpen, menuState.team, menuState.legal, menuState.history, menuState.companies, menuState.detailed] = false;
    });

    // NOTE • OnMount: Check for clicks
    useEffect(() => {
        menuState.team && setActivePage('team');
        menuState.history && setActivePage('history');
        menuState.companies && setActivePage('companies');
        menuState.legal && setActivePage('legal');
    }, [menuState.team, menuState.history, menuState.companies, menuState.legal]);

    // NOTE • Animate Properties
    const aniProps = {
        initial: {
            x: `100%`
        },
        animate: {
            x: `0%`,
            transition: {
                delay: 0.25,
                ease: [0.8, 0, 0.2, 1],
                duration: 1,
            }
        },
        exit: {
            x: `100%`,
            transition: {
                delay: 0.25,
                ease: [0.8, 0, 0.2, 1],
                duration: 1,
            }
        }
    }

    return (
        <AnimatePresence>
            {menuState.isOpen && (
                <Jacket {...aniProps}>
                    <CloseNav onClick={handleClose} className="cursor-trigger" />

                    <MainPages>
                        <Page onClick={handleTeam} activePage={activePage === 'team'} className="cursor-trigger">
                            <span>Team</span>
                        </Page>

                        <Page onClick={handleHistory} activePage={activePage === 'history'} className="cursor-trigger">
                            <span>History</span>
                        </Page>

                        <Page onClick={handleCompanies} activePage={activePage === 'companies'} className="cursor-trigger">
                            <span>Companies</span>
                        </Page>
                    </MainPages>

                    <LegalPage onClick={handleLegal} activePage={activePage === 'legal'} className="cursor-trigger">
                        <span>Legal</span>
                        <Icon type="legal" />
                    </LegalPage>
                </Jacket>
            )}
        </AnimatePresence>
    );
}

export default observer(NavBar);