// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div } from '@tackl';
import { motion } from "framer-motion";
import { h1Styles, emStyles, h5Styles, pStyles } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 1;

    opacity: ${props.isReady ? 1 : 0};
    transform: scale(${props.isReady ? 1 : 1.6});
    transition: opacity 1s ease-in-out, transform 1s ${props.theme.easing.bezzy};
    transition-delay: 2s;
`);

export const Brand = styled(motion.div)`
    position: absolute;
    z-index: 1;
    top: 3.6rem;
    left: 3.6rem;
    pointer-events: none;

    ${breakup.large`
        top: 6rem;
        left: 6rem;
    `}
`;

export const Content = styled(Div)(props => css`
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: flex-start;

    padding: 10.8rem 3.6rem 12rem;
    height: 100%;

    ${breakup.large`
        padding: 0;
        height: auto;
    `}
`);


export const Title = styled(motion.h1)(props => css`
    ${h1Styles}

    ${breakup.large`
        position: absolute;
        bottom: 6rem;
        left: 6rem;

        width: 100%;
        max-width: 80rem;
        width: 70%;
    `}

    ${breakup.xlarge`
        width: 75%;
        max-width: 80rem;
    `}
    ${breakup.xxlarge`  max-width: 108rem; `}
`);

export const Excerpt = styled(motion.p)(props => css`
    ${pStyles}
    color: ${props.theme.colors.brand.bc240};
    max-width: 24rem;

    ${breakup.large`
        position: absolute;
        top: 6rem;
        right: 6rem;

        width: 100%;
        max-width: 32rem;
        text-align: right;
    `}

    a {
        transition: ${props.theme.easing.ease};
        text-decoration: underline;

        &:hover {
            color: ${props.theme.colors.brand.bc1};
        }
    }
`);

export const Nav = styled(motion.nav)(props => css`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    border-top: 1px solid ${props.theme.colors.brand.bc280};
    border-bottom: none;
    border-left: none;
    border-right: none;

    overflow: hidden;


    ${breakup.large`
        bottom: 6rem;
        left: auto;
        right: 6rem;

        flex-flow: column;
        justify-content: flex-start;
        width: 16.3rem;

        border: 1px solid ${props.theme.colors.brand.bc260};
        border-radius: 0.4rem;
    `}
`)

export const NavItem = styled.button(props => css`
    ${emStyles}
    cursor: pointer;
    
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding: 4.8rem 2.4rem 2.4rem;

    background: transparent;
    color: ${props.theme.colors.brand.bc240};
    transition: ${props.theme.easing.ease};
    

    ${props.isMiddle && css`
        border-radius: 0;
        border-top: none;
        border-bottom: none;
        border-left: 1px solid ${props.theme.colors.brand.bc280};
        border-right: 1px solid ${props.theme.colors.brand.bc280};

        ${breakup.large`
            border-right: none;
            border-left: none;
            border-top: 1px solid ${props.theme.colors.brand.bc260};
            border-bottom: 1px solid ${props.theme.colors.brand.bc260};
        `}
    `}

    ${breakup.large`
        padding: 0 2.4rem;
        height: 6rem;
        justify-content: flex-start;
    `}

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 3rem;
        left: 50%;
        transform: translateX(-50%);

        width: 1.2rem;
        height: 1px;

        background: ${props.theme.colors.brand.bc240};

        ${breakup.large`
            top: 50%;
            left: auto;
            right: 2.4rem;
            transform: translateY(-50%);
        `}
    }

    &:after {
        transform: translateX(-50%) rotate(90deg);

        ${breakup.large`
            transform: translateY(-50%) rotate(90deg);
        `}
    }

    &:hover {
        ${breakup.large`
            background: ${props.theme.colors.brand.bc290};

            span {
                &:before {
                    transform: translateY(-240%) skewY(-24deg);
                    opacity: 0;
                }

                &:after {
                    transform: translateY(-50%) skewY(0deg);
                    opacity: 1;
                }
            }
        `}
    }

    &:active {
        background: ${props.theme.colors.brand.bc260};
    }

    span {
        color: transparent;
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        height: 50%;

        &:before,
        &:after {
            content: attr(data-label);
            position: absolute;
            top: 50%; left: 0;
            color: ${props.theme.colors.brand.bc240};
            transition: ${props.theme.easing.ease};
        }

        &:before {
            transform: translateY(-50%) skewY(0deg);
            opacity: 1;
        }

        &:after {
            transform: translateY(240%) skewY(-24deg);
            opacity: 0;
        }
    }
`);