// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import { motion } from "framer-motion";
import { emStyles } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(motion.nav)(props => css`
    position: fixed;
    z-index: 12;
    top: 0; right: 0; bottom: 0;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;

    width: 6rem;
    background: ${props.theme.colors.global.white};

    &:before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 0; left: 0; bottom: 0;
        width: 1px;
        background: ${props.theme.colors.brand.bc210};
    }
`);

export const MainPages = styled.div`
    position: relative;
    z-index: 3;
    transform: rotate(90deg) translateX(-2.4rem);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3.6rem;

    width: 6rem;
    height: 6rem;

    ${breakup.large`
        transform: rotate(90deg) translateX(0rem);
    `}
`;


export const Page = styled.button(props => css`
    position: relative;
    color: ${props.theme.colors.brand.bc220};
    height: 6rem;
    padding: 0;
    background: transparent;
    transition: ${props.theme.easing.ease};
    cursor: pointer;

    &:hover {
        span { color: ${props.theme.colors.brand.bc2}; }
    }

    &:after {
        content: "";
        position: absolute;
        right: 0; left: 0; bottom: 0;
        height: 1px;
        background: ${props.activePage ? props.theme.colors.brand.bc1 : 'transparent'};
        transform: scaleX(${props.activePage ? 1 : 0});
        transition: all 1s ${props.theme.easing.bezzy};
    }

    > span {
        ${emStyles}
        display: block;
        color: inherit;
        padding: 0 1.2rem;
        transition: ${props.theme.easing.ease};

        ${props.activePage && css`
            color: ${props.theme.colors.brand.bc2};
        `}
    }
`);

export const LegalPage = styled.button(props => css`
    color: ${props.theme.colors.brand.bc220};

    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: transparent;

    width: 6rem;
    height: 11.5rem;
    padding: 1.2rem 0 2.4rem;
    cursor: pointer;

    &:after {
        content: "";
        position: absolute;
        top: 0; left: 0; bottom: 0;
        width: 1px;
        background: ${props.activePage ? props.theme.colors.brand.bc1 : 'transparent'};
        transform: scaleY(${props.activePage ? 1 : 0});
        transition: all 1s ${props.theme.easing.bezzy};
    }


    svg {
        fill: ${props.theme.colors.brand.bc220};
        transition: ${props.theme.easing.ease};

        ${props.activePage && css`
            fill: ${props.theme.colors.brand.bc2};
        `}
    }

    span {
        ${emStyles}
        position: absolute;
        top: 1.2rem;
        left: 50%;

        display: block;
        color: ${props.activePage ? props.theme.colors.brand.bc2 : 'inherit'};
        transform: translateX(-50%) translateY(1.6rem) rotate(90deg);
        transform-origin: center center;
        transition: ${props.theme.easing.ease};
    }

    &:hover {
        ${breakup.large`
            span { color: ${props.theme.colors.brand.bc2}; }
            svg { fill: ${props.theme.colors.brand.bc2}; }
        `}
    }
`);