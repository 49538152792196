// Imports
// ------------
import styled, { css } from 'styled-components';

// Exports
// ------------
export const Jacket = styled.button(props => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 6rem;
    height: 6rem;
    background: transparent;
    padding: 0;
    cursor: pointer;

    &:hover {
        &:before {
            opacity: 0.05;
            transform: scale(1);
        }

        svg {
            stroke: ${props.theme.colors.brand.bc2};
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: 0.6rem;
        left: 0.6rem;
        right: 0.6rem;
        bottom: 0.6rem;

        background: ${props.theme.colors.brand.bc2};
        border-radius: 0.4rem;
        opacity: 0;
        transform: scale(1.2);
        transition: ${props.theme.easing.ease};
    }

    svg {
        width: 2.4rem;
        height: 2.4rem;
        stroke: ${props.theme.colors.brand.bc240};
        transition: ${props.theme.easing.ease};
    }
`);
