// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import { spanStyles } from '@tackl-type';
import { motion } from 'framer-motion';

// Exports
// ------------
export const Jacket = styled(motion.span)(props => css`
    ${spanStyles}
    position: absolute;
    z-index: 2;
    top: 4.3rem;
    right: 3.6rem;
    color: ${props.theme.colors.brand.bc240};

    ${breakup.large`
        bottom: 6rem;
        left: 6rem;
        right: auto;
        top: auto;

        color: ${props.theme.colors.brand.bc260};
    `}

    a {
        display: inline-block;
        color: inherit;
        font-weight: ${props.theme.font.weight.bold};
        transition: ${props.theme.easing.ease};

        &:hover {
            ${breakup.large`
                color: ${props.theme.colors.brand.bc1};
            `}
        }
    }
`);