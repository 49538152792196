// Imports
// ------------
import React from 'react';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const Copyright = () => {
    const d = new Date();
    const year = d.getFullYear();

    return (
        <Jacket
            initial={{
                opacity: 0,
                y: '100%'
            }}
            animate={{
                opacity: 1,
                y: '0%',
                transition: {
                    delay: 1,
                    duration: 1,
                    ease: [0.8, 0, 0.2, 1]
                }
            }}
            exit={{
                opacity: 0,
                y: '100%',
                transition: {
                    delay: 0,
                    duration: 0.75,
                    ease: [0.8, 0, 0.2, 1]
                }
            }}
            transition={{
                duration: 1,
                ease: [0.8, 0, 0.2, 1]
            }}
        >&copy; {year} • Site by <a href="https://12studio.agency" target="_blank" rel="noopener noreferrer">12</a></Jacket>
    );
}

export default Copyright;