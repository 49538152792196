// Imports
// ------
import React from 'react';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const Icon = ({ type, className, onClick }) => {
	if (type === 'facebook')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d='M24,12.072A12,12,0,1,0,10.125,23.926V15.541H7.078V12.072h3.047V9.428c0-3.007,1.792-4.669,4.532-4.669a18.611,18.611,0,0,1,2.687.234V7.947H15.83a1.734,1.734,0,0,0-1.947,1.49,1.71,1.71,0,0,0-.008.385v2.25H17.2l-.532,3.469h-2.8v8.385A12,12,0,0,0,24,12.072Z' />
			</Jacket>
		);

	if (type === 'codepen')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d='M24,8.2C24,8.2,24,8.2,24,8.2c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1 c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l-11-7.3 c-0.3-0.2-0.8-0.2-1.1,0l-11,7.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1 c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1v7.3 c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1 c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l11,7.3c0.2,0.1,0.4,0.2,0.6,0.2 c0.2,0,0.4-0.1,0.6-0.2l11-7.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1 c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1V8.3 C24,8.3,24,8.3,24,8.2z M13,3l8.1,5.4l-3.6,2.4l-4.5-3V3z M11,3v4.8l-4.5,3L2.9,8.3L11,3z M2.1,10.3L4.6,12l-2.6,1.7V10.3z M11,21 l-8.1-5.4l3.6-2.4l4.5,3V21z M12,14.4L8.4,12L12,9.6l3.6,2.4L12,14.4z M13,21v-4.8l4.5-3l3.6,2.4L13,21z M21.9,13.7L19.4,12l2.6-1.7 V13.7z' />
			</Jacket>
		);

	if (type === 'dribbble')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M22.1,13.6c-0.4-0.1-3.2-1-6.4-0.4c1.3,3.7,1.9,6.7,2,7.3C20,19,21.7,16.5,22.1,13.6z M16,21.5c-0.2-0.9-0.7-4-2.2-7.8 c0,0,0,0-0.1,0c-5.8,2-7.9,6-8,6.4c1.7,1.4,3.9,2.2,6.3,2.2C13.4,22.3,14.8,22,16,21.5z M4.4,18.9c0.2-0.4,3-5.1,8.3-6.8 c0.1,0,0.3-0.1,0.4-0.1c-0.3-0.6-0.5-1.2-0.8-1.7c-5.1,1.5-10.1,1.5-10.5,1.5c0,0.1,0,0.2,0,0.3C1.8,14.6,2.7,17.1,4.4,18.9z M2,9.9 c0.5,0,4.7,0,9.5-1.2c-1.7-3-3.5-5.6-3.8-5.9C4.8,4.1,2.6,6.7,2,9.9z M9.6,2.1c0.3,0.4,2.1,2.9,3.8,6c3.6-1.4,5.2-3.4,5.4-3.7 C17,2.7,14.6,1.8,12,1.8C11.2,1.8,10.4,1.9,9.6,2.1z M19.9,5.5c-0.2,0.3-1.9,2.5-5.7,4c0.2,0.5,0.5,1,0.7,1.5 c0.1,0.2,0.1,0.4,0.2,0.5c3.4-0.4,6.8,0.3,7.1,0.3C22.2,9.5,21.4,7.3,19.9,5.5z'
				/>
			</Jacket>
		);

	if (type === 'instagram')
		return (
			<Jacket xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<path d="M12,2.982c2.937,0,3.285.011,4.445.064a6.072,6.072,0,0,1,2.042.379,3.4,3.4,0,0,1,1.265.823,3.4,3.4,0,0,1,.823,1.265,6.072,6.072,0,0,1,.379,2.042c.053,1.16.064,1.508.064,4.445s-.011,3.285-.064,4.445a6.072,6.072,0,0,1-.379,2.042,3.644,3.644,0,0,1-2.088,2.088,6.072,6.072,0,0,1-2.042.379c-1.16.053-1.508.064-4.445.064s-3.285-.011-4.445-.064a6.072,6.072,0,0,1-2.042-.379,3.4,3.4,0,0,1-1.265-.823,3.4,3.4,0,0,1-.823-1.265,6.072,6.072,0,0,1-.379-2.042c-.053-1.16-.064-1.508-.064-4.445s.011-3.285.064-4.445a6.072,6.072,0,0,1,.379-2.042,3.4,3.4,0,0,1,.823-1.265,3.4,3.4,0,0,1,1.265-.823,6.072,6.072,0,0,1,2.042-.379c1.16-.053,1.508-.064,4.445-.064M12,1c-2.987,0-3.362.013-4.535.066a8.108,8.108,0,0,0-2.67.511A5.625,5.625,0,0,0,1.577,4.8a8.108,8.108,0,0,0-.511,2.67C1.013,8.638,1,9.013,1,12s.013,3.362.066,4.535a8.108,8.108,0,0,0,.511,2.67A5.625,5.625,0,0,0,4.8,22.423a8.108,8.108,0,0,0,2.67.511C8.638,22.987,9.013,23,12,23s3.362-.013,4.535-.066a8.108,8.108,0,0,0,2.67-.511A5.625,5.625,0,0,0,22.423,19.2a8.108,8.108,0,0,0,.511-2.67C22.987,15.362,23,14.987,23,12s-.013-3.362-.066-4.535a8.108,8.108,0,0,0-.511-2.67A5.625,5.625,0,0,0,19.2,1.577a8.108,8.108,0,0,0-2.67-.511C15.362,1.013,14.987,1,12,1Z" />
				<path d="M12,6.351A5.649,5.649,0,1,0,17.649,12,5.649,5.649,0,0,0,12,6.351Zm0,9.316A3.667,3.667,0,1,1,15.667,12,3.667,3.667,0,0,1,12,15.667Z" />
				<circle cx="17.872" cy="6.128" r="1.32" />
			</Jacket>
		)

	if (type === 'linkedin')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d='M23,0H1C0.4,0,0,0.4,0,1v22c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V1C24,0.4,23.6,0,23,0z M7.1,20.5H3.6V9h3.6 V20.5z M5.3,7.4c-1.1,0-2.1-0.9-2.1-2.1c0-1.1,0.9-2.1,2.1-2.1c1.1,0,2.1,0.9,2.1,2.1C7.4,6.5,6.5,7.4,5.3,7.4z M20.5,20.5h-3.6 v-5.6c0-1.3,0-3-1.8-3c-1.9,0-2.1,1.4-2.1,2.9v5.7H9.4V9h3.4v1.6h0c0.5-0.9,1.6-1.8,3.4-1.8c3.6,0,4.3,2.4,4.3,5.5V20.5z' />
			</Jacket>
		);

	if (type === 'back')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d='M1.293,11.293L9,3.586L10.414,5l-6,6H22c0.553,0,1,0.448,1,1s-0.447,1-1,1H4.414l6,6L9,20.414l-7.707-7.707 C0.902,12.316,0.902,11.684,1.293,11.293z' />
			</Jacket>
		);

	if (type === 'up')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d='M12.707,1.293L20.414,9L19,10.414l-6-6V22c0,0.553-0.448,1-1,1s-1-0.447-1-1V4.414l-6,6L3.586,9l7.707-7.707 C11.684,0.902,12.316,0.902,12.707,1.293z' />
			</Jacket>
		);

	if (type === 'legal')
		return (
			<Jacket width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M21.217 3.02277L12.217 1.02277C12.074 0.991759 11.926 0.991759 11.783 1.02277L2.783 3.02277C2.56077 3.07217 2.36205 3.19592 2.21968 3.37356C2.07731 3.55121 1.99981 3.77211 2 3.99977V13.9998C2 16.6519 3.05357 19.1955 4.92893 21.0708C6.8043 22.9462 9.34784 23.9998 12 23.9998C14.6522 23.9998 17.1957 22.9462 19.0711 21.0708C20.9464 19.1955 22 16.6519 22 13.9998V3.99977C22.0002 3.77211 21.9227 3.55121 21.7803 3.37356C21.638 3.19592 21.4392 3.07217 21.217 3.02277ZM17 16.9998C17 17.265 16.8946 17.5193 16.7071 17.7069C16.5196 17.8944 16.2652 17.9998 16 17.9998H8C7.73478 17.9998 7.48043 17.8944 7.29289 17.7069C7.10536 17.5193 7 17.265 7 16.9998V11.9998C7 11.7346 7.10536 11.4802 7.29289 11.2927C7.48043 11.1051 7.73478 10.9998 8 10.9998H9V8.99977C9 8.20412 9.31607 7.44106 9.87868 6.87845C10.4413 6.31584 11.2044 5.99977 12 5.99977C12.7956 5.99977 13.5587 6.31584 14.1213 6.87845C14.6839 7.44106 15 8.20412 15 8.99977V10.9998H16C16.2652 10.9998 16.5196 11.1051 16.7071 11.2927C16.8946 11.4802 17 11.7346 17 11.9998V16.9998Z" />
				<path d="M12 8C11.7348 8 11.4804 8.10536 11.2929 8.29289C11.1054 8.48043 11 8.73478 11 9V11H13V9C13 8.73478 12.8946 8.48043 12.7071 8.29289C12.5196 8.10536 12.2652 8 12 8Z" />
			</Jacket>

		);

	if (type === 'arrow-right')
		return (
			<Jacket
				width="24"
				height="25"
				viewBox="0 0 24 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				onClick={onClick}>
				<path d="M10 8.66602L14 12.666L10 16.666" strokeMiterlimit="10" strokeLinecap="square" />
			</Jacket>

		)

	if (type === 'arrow-link')
		return (
			<Jacket xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<line x1="2" y1="22" x2="22" y2="2" strokeLinecap="butt" />
				<polyline points="12 2 22 2 22 12" />
			</Jacket>
		);

	if (type === 'arrow-down')
		return (
			<Jacket xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
				<path d="M24 2V45" strokeMiterlimit="10" />
				<path d="M10 31L24 45L38 31" strokeMiterlimit="10" strokeLinecap="square" />
			</Jacket>
		);

	if (type === 'close')
		return (
			<Jacket width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M19 5L5 19" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
				<path d="M19 19L5 5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
			</Jacket>
		);

	if (type === 'external')
		return (
			<Jacket width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M15 0H7.99999V2H12.6L6.29999 8.3L7.69999 9.7L14 3.4V8H16V1C16 0.4 15.6 0 15 0Z" />
				<path d="M14 16H1C0.4 16 0 15.6 0 15V2C0 1.4 0.4 1 1 1H5V3H2V14H13V11H15V15C15 15.6 14.6 16 14 16Z" />
			</Jacket>

		);

	console.error(
		`You've not added the correct 'type' prop to your <Icon /> component`
	);
	console.warn(
		`Remember to add "type='YOUR_ICON_NAME'" to your <Icon /> props or else your icon wont display`
	);
	return null;
};

export default Icon;
