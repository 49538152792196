// Imports
// ------------
import React from 'react';
import WebglGlobe from '@parts/WebglGlobe';
import Hero from '@parts/Hero';
import NavBar from '@parts/NavBar';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

// Lazy
// ------------
const Cursor = loadable(() => import('@parts/Cursor'));
const AnimatedLogo = loadable(() => import('@parts/AnimatedLogo'));
const Team = loadable(() => import('@parts/Team'));
const History = loadable(() => import('@parts/History'));
const Legal = loadable(() => import('@parts/Legal'));
const Companies = loadable(() => import('@parts/Companies'));

// Component
// ------------
const IndexPage = ({ location, data }) => {
	// NOTE • Breakpoints
	const bp = useBreakpoint();

	return (
		<>

			<NavBar />
			<WebglGlobe />
			{bp.large ? (
				<>
					<Cursor location={location} />
					<AnimatedLogo />
				</>
			) : null}

			<Hero title={data.hero.title} titleOptions={data.hero.titleOptions} excerpt={data.hero.excerpt} />
			<Team team={data.team.nodes} />
			<History content={data.history} />
			<Companies companies={data.companies.nodes} />
			<Legal content={data.legal} />
		</>
	);
}

export default IndexPage;

// GraphQL
// ------------
export const query = graphql`
	query HomePageQuery {
		# ANCHOR Home
		hero: datoCmsHome {
			title
			excerpt
		}

		# ANCHOR Team
		team: allDatoCmsTeam(sort: {order: ASC, fields: position}) {
			nodes {
				id: originalId
				name
				linkedin: linkedinUrl
				desc: description
				picThumb: profilePicture {
					alt
					image: gatsbyImageData(
						placeholder: BLURRED,
						layout: FULL_WIDTH,
						width: 324,
						height: 380
					)
				}
				picFull: profilePicture {
					alt
					image: gatsbyImageData(
						placeholder: BLURRED,
						layout: FULL_WIDTH,
						width: 512,
						height: 600
					)
				}
				areas {
					id: originalId
					area: areaTitle
				}
			}
		}

		# ANCHOR • History
		history: datoCmsHistory {
			title
			subTitle
			mainContent
			
			image: featuredImage{
				alt
				data: gatsbyImageData(
					placeholder: BLURRED,
					layout: FULL_WIDTH,
					width: 960,
				)
			}
			
			years: yearsOfHistory
			deployed: amountDeployed
		}

		# ANCHOR Legal
		legal: datoCmsLegal {
			title
			jargon: legalJargon
			mainContent

		}

		# ANCHOR Companies
		companies: allDatoCmsCompany(sort: {order: ASC, fields: companyName}) {
			nodes {
				id: originalId
				name: companyName
				logo: companyLogo {
					alt
					url
					format
					data: gatsbyImageData(
						placeholder: BLURRED,
						layout: FULL_WIDTH,
					)
				}
				desc: companyDescription
				website: companyWebsite
				invested: yearInvested
				country: countryName
				areas: areaOfBusiness {
					id: originalId
					area: areaTitle
				}
			}
		}
	}
`
